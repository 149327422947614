import React from 'react';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';
import { Auth, Hub, Logger } from 'aws-amplify';

function redirect_to_krach(){
  // accessing token and redirect
  Auth.currentSession().then(res=>{
    let accessToken = res.getAccessToken()
    let jwt = accessToken.getJwtToken()

    const current_url = window.location.hostname;
    if(jwt){
      let redirect_url = `http://localhost:8080/#id_token=${jwt}`
      if(current_url !== "localhost") {
        redirect_url = `http://tool.krach.ai/#id_token=${jwt}`
      }
      
      window.location.replace(redirect_url);
    }
  })
}

function send_notification(){
  return Auth.currentAuthenticatedUser().then(res=>{
    const params = {
      text: `A new user with the ID: ${res.username} has signed up!`
    };
  
    const options = {
        method: 'POST',
        body: JSON.stringify( params )
    };
    const domain = "https://hooks.slack.com/services/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"
  
    fetch( domain, options )
        .then( response => response.json() )
        .then( response => {
            // Do something with response.
            console.log(response);
    } );
  
  })
}

const App = () => {
  return (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[
          {
            type: "email",
            // label: "Custom Email Label",
            placeholder: "email",
            inputProps: { required: true, autocomplete: "username" },
          },
          {
            type: "password",
            // label: "Password",
            placeholder: "password",
            inputProps: { required: true, autocomplete: "new-password" },
          }
        ]} 
      >
        <div slot="header-subtitle">
          By registering, you agree to our 
          <a href="https://tool.krach.ai/sections/terms.html" target="_blank" rel="noopener noreferrer">
            Terms and Conditions of Use
          </a> 
          and confirm that you have read our 
          <span><a href="https://tool.krach.ai/sections/privacy-policy.html" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a></span> to learn how we process your data.
        </div>
      </AmplifySignUp>
      <AmplifySignIn slot="sign-in" usernameAlias="email" />
      <amplify-button class="hydrated">
        <button className="button" onClick={redirect_to_krach} type="button">Go Back making Krach</button>
      </amplify-button>
      <div style={{height:"50px"}}></div>
      <AmplifySignOut></AmplifySignOut>
    </AmplifyAuthenticator>
  );
};


const logger = new Logger('My-Logger');

const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
            logger.info('user signed in');
            redirect_to_krach()
            break;
        case 'signUp':
            logger.info('user signed up');
            send_notification()
            break;
        case 'signOut':
            logger.info('user signed out');
            break;
        case 'signIn_failure':
            logger.error('user sign in failed');
            break;
        case 'tokenRefresh':
            logger.info('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            logger.error('token refresh failed');
            break;
        case 'configured':
            logger.info('the Auth module is configured');
            break;
        default:
            logger.info('unknown event');
    }
}

Hub.listen('auth', listener);

export default App
